/* eslint-disable max-len */
import React from 'react';
const GithubIcon = () => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M24 0H0V24H24V0Z" fill="#1A6449"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9817 6.14355C8.6789 6.14355 6 8.82245 6 12.1252C6 14.7674 7.72477 17.0059 10.0734 17.8133C10.367 17.85 10.4771 17.6665 10.4771 17.5197C10.4771 17.3729 10.4771 17.0059 10.4771 16.4922C8.82569 16.8592 8.45872 15.6848 8.45872 15.6848C8.20183 14.9876 7.79817 14.8041 7.79817 14.8041C7.24771 14.4371 7.83486 14.4371 7.83486 14.4371C8.42202 14.4738 8.75229 15.061 8.75229 15.061C9.30275 15.9784 10.1468 15.7215 10.4771 15.5747C10.5138 15.1711 10.6972 14.9142 10.844 14.7674C9.52294 14.6206 8.12844 14.1069 8.12844 11.7949C8.12844 11.1344 8.34862 10.6206 8.75229 10.1803C8.7156 10.0702 8.49541 9.44631 8.82569 8.63897C8.82569 8.63897 9.33945 8.49218 10.4771 9.26282C10.9541 9.11603 11.4679 9.07933 11.9817 9.07933C12.4954 9.07933 13.0092 9.15273 13.4862 9.26282C14.6239 8.49218 15.1376 8.63897 15.1376 8.63897C15.4679 9.44631 15.2477 10.0702 15.211 10.2169C15.578 10.6206 15.8349 11.1711 15.8349 11.8316C15.8349 14.1436 14.4404 14.6206 13.1193 14.7674C13.3394 14.9509 13.5229 15.3179 13.5229 15.8683C13.5229 16.6757 13.5229 17.2995 13.5229 17.5197C13.5229 17.6665 13.633 17.85 13.9266 17.8133C16.3119 17.0059 18 14.7674 18 12.1252C17.9633 8.82245 15.2844 6.14355 11.9817 6.14355Z" fill="#FAFAFA"/>
    </svg>
  </div>
);

export default GithubIcon;
